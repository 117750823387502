import React from "react";
import App from "./src/components/app";
require("prism-themes/themes/prism-vsc-dark-plus.css");

export const wrapRootElement = ({ element }) => {
    return <App>{element}</App>;
};

export const onRouteUpdate = () => {
    switch (process.env.NODE_ENV) {
        case "production":
            navigator.serviceWorker.register("/sw.js").then((reg) => {
                reg.update();
            });
            break;
        default:
    }
};

export const onServiceWorkerUpdateReady = () => window.location.reload();
