import React from "react";

export const ThemeContext = React.createContext({
    darkModeTheme: "dark",
    toggleDarkMode: () => {},
});

export const useDarkMode = (): [string, () => void, boolean] => {
    const [theme, setTheme] = React.useState("");
    const [componentMounted, setComponentMounted] = React.useState(false);
    const setMode = (mode: string) => {
        window.localStorage.setItem("theme", mode);
        setTheme(mode);
        document.body.className = mode === "dark" ? "bp4-dark" : "";
    };

    const toggleTheme = () => {
        if (theme === "light") {
            setMode("dark");
        } else {
            setMode("light");
        }
    };

    React.useEffect(() => {
        const localTheme = window.localStorage.getItem("theme");

        if (!localTheme) {
            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                setMode("dark");
            } else {
                setMode("light");
            }
        } else {
            setMode(localTheme);
        }

        setComponentMounted(true);
    }, [componentMounted]);

    return [theme, toggleTheme, componentMounted];
};

export const ThemeProvider = ({ children }) => {
    const [darkModeTheme, toggleDarkMode] = useDarkMode();
    return <ThemeContext.Provider value={{ darkModeTheme, toggleDarkMode }}>{children}</ThemeContext.Provider>;
};
